






































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Mutation } from 'vuex-class'
import ManageTable from '@/components/shared/ManageTable.vue'
import { invalidApplyTransferOptions, applyTransferRules } from '@/data/ApplyTransferFormData'
import { regexpOptions } from '@/data/Regex'
import { ReceiveMethodEnum } from '@/enums'
import MemberInfo from '@/models/MemberInfo'
import { ApplyTransfer } from '@/views/transfer/model/ApplyTransfer'
import Recipient from '@/models/recipient/Recipient'
import { TransferRow } from '@/presentation/remittance/model/TransferRows'
import { GetRecipients, GetRecipientsParam } from '@/gateway/recipient/GetRecipients'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import BaseCurrencyInfo from '@/components/remittance/BaseCurrencyInfo.vue'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'
import ManageTableOptions from '@/models/forms/ManageTableOptions'

@Component({
  components: { BaseCurrencyInfo, BasicDialog, ManageTable }
})
export default class InvalidApply extends Vue {
  @Getter getApplyTransfers: any
  @Mutation setValidatedTransfers: any
  @Mutation disableLoading: any

  transfers: Array<ApplyTransfer> = []
  invalidTransfers: Array<ApplyTransfer> = []
  pageInvalidTransfers: Dictionary<Array<ApplyTransfer>> = {}
  validatedTransfers: Array<ApplyTransfer> = []
  recipients: Array<Recipient> = []
  unReceivedCorpPids: Array<string> = []
  page: number = 1
  unit: number = 5
  totalInvalidCount: number = 0
  tableOption: Dictionary<ManageTableOptions> = invalidApplyTransferOptions
  descriptor: Dictionary<any> = applyTransferRules
  sheetName!: string
  baseCurrencyInfoDialog = false

  get hasInvalidTransfers (): boolean {
    return this.totalInvalidCount !== this.validatedTransfers.length
  }

  openInfoModal () {
    this.baseCurrencyInfoDialog = true
  }

  apply (): void {
    if (!this.hasInvalidTransfers) {
      this.transfers.push(...this.validatedTransfers)
      let id = 1
      const filteredValidatedTransfers: Dictionary<Array<TransferRow>> = {}
      this.transfers.forEach(transfer => {
        const recipient = this.recipients.find(recipient => recipient.corp_pid === transfer.corps_id)
        if (!recipient) return
        const country: string = recipient.country
        const remittanceMethod = ReceiveMethodEnum[recipient.remittance_method_type]
        const countryWithMethod = `${country.toUpperCase()}_${remittanceMethod.toUpperCase()}`
        const validatedTransfer: TransferRow = {
          id: id++,
          corps_id: recipient.corp_pid.toString(),
          full_name: `${recipient.first_name} ${recipient.middle_name ?? ''} ${recipient.last_name ?? ''}`,
          amount: transfer.amount,
          base_currency: transfer.base_currency,
          invoice: { value: '', name: '' },
          comment: transfer.comment,
          updatable: false
        }
        if (!filteredValidatedTransfers[countryWithMethod]) filteredValidatedTransfers[countryWithMethod] = []
        filteredValidatedTransfers[countryWithMethod].push(validatedTransfer)
      })
      this.setValidatedTransfers(filteredValidatedTransfers)
      this.$router.replace('apply')
    }
  }

  deleteRow (countryWithMethod: string, index: number): void {
    const invalidTransfer = this.pageInvalidTransfers[this.page][index]
    const invalidTransferIndex = this.invalidTransfers.findIndex(transfer => transfer.id === invalidTransfer.id)
    if (invalidTransferIndex > -1) this.invalidTransfers.splice(invalidTransferIndex, 1)
    this.totalInvalidCount--
    this.setPaginatedInvalidTransfers()
    this.validatedTransfers = this.validatedTransfers.filter(transfer => transfer.id !== invalidTransfer.id)
    this.applyAutoWithMessage(1)
  }

  async toggleUpdatable (page: string, index: number, callback: any): Promise<void> {
    const updateTransferRow = Object.assign({}, this.pageInvalidTransfers[page][index])
    const isCompleteAction = updateTransferRow.updatable === true
    if (isCompleteAction) {
      await this.getRecipients([updateTransferRow.corps_id])
      const isInvalid = this.checkTransferValidate(updateTransferRow)
      if (isInvalid) {
        this.pageInvalidTransfers[page][index] = Object.assign({}, updateTransferRow)
        return
      }
      const validatedIndex = this.validatedTransfers.findIndex(transfer => transfer.id === updateTransferRow.id)
      updateTransferRow.updatable = false
      if (validatedIndex < 0) {
        this.validatedTransfers.push(updateTransferRow)
      } else {
        this.validatedTransfers.splice(validatedIndex, 1, updateTransferRow)
      }
      this.validatedTransfers = Array(...this.validatedTransfers)
    }
    if (callback) callback()
  }

  checkTransferValidate (transfer: Dictionary<any>): boolean {
    const invalidProps: Array<string> = []
    Object.keys(transfer).forEach(key => {
      if (typeof transfer[key] === 'string' && key !== 'comment') {
        if (transfer[key].includes('-')) transfer[key] = transfer[key].replace(/-/g, '')
        if (transfer[key].includes(' ')) transfer[key] = transfer[key].replace(/-/g, '')
        transfer[key] = transfer[key].trim()
      }
      if (this.isInvalidRegex(key, transfer[key])) {
        invalidProps.push(key)
        transfer[`${key}_message`] = 'invalid format'
      } else {
        delete transfer[`${key}_class`]
        delete transfer[`${key}_message`]
      }
      if (key.includes('undefined')) delete transfer[key]
    })
    const hasInvalidRegex: boolean = !!invalidProps.length

    const recipient: Dictionary<any> | undefined = this.recipients.find(recipient => recipient.corp_pid === transfer.corps_id)
    transfer.country = recipient?.country

    const corpsIdString: string = 'corps_id'
    const hasNoRecipient = !recipient
    if (hasNoRecipient && !invalidProps.includes(corpsIdString)) {
      transfer[`${corpsIdString}_message`] = 'Recipient Not Exist'
      invalidProps.push(corpsIdString)
    } else {
      delete transfer[`${corpsIdString}_class`]
      delete transfer[`${corpsIdString}_message`]
    }

    const isInvalidAmount = !Number(transfer.amount)
    if (isInvalidAmount) {
      invalidProps.push('amount')
      transfer['amount_message'] = `invalid format`
    } else {
      delete transfer['amount_class']
      delete transfer['amount_message']
    }

    const availableCurrencies: Array<string> = ['USD', 'KRW']
    const currency: string | undefined = recipient?.currency
    if (currency) availableCurrencies.push(currency)
    const baseCurrencyString = 'base_currency'
    const isInValidBaseCurrency = !hasNoRecipient && !availableCurrencies.includes(transfer.base_currency)
    if (isInValidBaseCurrency) {
      invalidProps.push(baseCurrencyString)
      transfer[`${baseCurrencyString}_message`] = `${currency}, KRW and USD only`
    } else {
      delete transfer[`${baseCurrencyString}_class`]
      delete transfer[`${baseCurrencyString}_message`]
    }

    const isInValidComment = transfer.comment && transfer.comment.length > 20
    if (isInValidComment) {
      invalidProps.push('comment')
      transfer['comment_message'] = `${transfer.comment.length}/20`
    } else {
      delete transfer['comment_class']
      delete transfer['comment_message']
    }

    invalidProps.forEach(prop => {
      if (!transfer[prop]) transfer[prop] = ''
      const propClass = `${prop}_class`
      transfer[propClass] = 'is-error'
    })

    return hasInvalidRegex || hasNoRecipient || isInvalidAmount || isInValidBaseCurrency || isInValidComment
  }

  filterValid (): Array<ApplyTransfer> {
    let id = 0
    const transfers: Array<ApplyTransfer> = this.transfers
    return transfers.filter((transfer: ApplyTransfer) => {
      const isInvalid = this.checkTransferValidate(transfer)
      if (isInvalid) {
        transfer.id = id++
        transfer.updatable = true
        transfer = Object.assign({}, transfer)
        this.invalidTransfers.unshift(transfer)
      }
      return !isInvalid
    })
  }

  isInvalidRegex (prop: string, value: any): boolean {
    const regex: RegExp = regexpOptions[prop]
    if (!regex) return false
    return !regex.test(value)
  }

  setTransfer (): void {
    const sheetName = Object.keys(this.getApplyTransfers)[0]
    this.sheetName = sheetName
    const transfers: Array<ApplyTransfer> = Array(...this.getApplyTransfers[sheetName])
    this.transfers = transfers.map((transfer: ApplyTransfer) => {
      const corpsId = transfer.corps_id ? transfer.corps_id.toString() : ''
      const amount = transfer.amount ? transfer.amount.toString() : ''
      const comment = transfer.comment ? transfer.comment.toString() : ''
      const baseCurrency = transfer.base_currency || ''
      return {
        corps_id: corpsId,
        amount,
        base_currency: baseCurrency,
        comment
      }
    })
  }

  getCorpPids (): Array<string> {
    return this.transfers.map((transfer: ApplyTransfer) => {
      return transfer.corps_id
    })
  }

  async getRecipients (corpPids: Array<string>): Promise<void> {
    if (!MemberInfoGateway.hasAuthInfo()) {
      await this.$router.push('/signIn')
      return
    }
    const memberInfo: MemberInfo = MemberInfoGateway.getMemberInfo()
    const getRecipientsParam: GetRecipientsParam = {
      corp_id: memberInfo.corp_id,
      corp_pids: corpPids,
      page: 1,
      unit: corpPids.length
    }
    const getRecipientsResponse = await GetRecipients.getInstance().request(getRecipientsParam)
    const recipients = getRecipientsResponse.list
    if (!recipients?.length) return
    const receivedCorpPids: Array<string> = recipients.map((recipient: Recipient) => recipient.corp_pid)
    const unReceivedCorpPids = corpPids.filter(pid => !receivedCorpPids.includes(pid))
    this.unReceivedCorpPids.push(...unReceivedCorpPids)
    this.recipients.push(...recipients)
  }

  filterInvalidParsingRows (): void {
    const transfers = this.filterValid()
    this.totalInvalidCount = this.invalidTransfers.length
    this.transfers = Array(...transfers)
  }

  setPaginatedInvalidTransfers (): void {
    const invalidTransfers: Array<ApplyTransfer> = Array(...this.invalidTransfers)
    let page: number = 1
    while (invalidTransfers.length > 0) {
      this.pageInvalidTransfers[page++] = invalidTransfers.splice(0, this.unit)
    }
  }

  applyAutoWithMessage (timerSec?: number) {
    if (this.totalInvalidCount || this.hasInvalidTransfers) return
    this.$message.success(`${this.$t('notification.all_info_valid')}`)
    if (timerSec) {
      setTimeout(() => {
        this.apply()
      }, timerSec * 1000)
    } else {
      this.apply()
    }
  }

  async created () {
    this.setTransfer()
    const corpsPid: Array<string> = this.getCorpPids()
    await this.getRecipients(corpsPid)
    this.filterInvalidParsingRows()
    await this.setPaginatedInvalidTransfers()
    this.disableLoading()
    this.applyAutoWithMessage()
  }
}
